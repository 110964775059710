<script>
import { reactive, toRefs, onMounted } from 'vue'
import { useStore } from 'vuex'

const questionText = 'What is your weight (lbs)?'

const stepName = 'Weight'

export default {
  name: stepName,

  emits: ['next-step'],

  setup(_, {emit}) {
    const store = useStore()

    onMounted(() => {
      store.commit('SET_IS_LOADING', false)
    })

    const setAnswer = () => {
      state.error = state.weight < 80 || state.weight > 500

      if (state.error) return

      store.commit('SET_FIELD', {field: 'weight', value: `${state.weight}_lbs`})
      emit('next-step', stepName)
    }

    const handler = () => {
      state.error = false
    }

    const state = reactive({
      weight: '', //threshold greater than or equal to 80 and less than or equal to 500
      error: false
    })

    return {
      ...toRefs(state),
      questionText,
      handler,
      setAnswer
    }
  }
}
</script>

<template>
  <div>
    <div class="row">
      <div class="box">
        <h2 class="main-heading button-answers-heading">{{questionText}}</h2>
      </div>
    </div>

    <div class="row">
      <div class="box">
        <input
          class="form-input weight-input"
          :class="{'weight-error' : error}"
          type="text"
          maxlength="3"
          placeholder="Pounds"
          v-model="weight"
          @input="handler"
        >
      </div>
    </div>

    <div class="row">
      <div class="box">
        <button
          class="main-button main-button-w"
          @click="setAnswer"
        >
          Continue
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '../../../assets/styles/component_styles/questionnaire/weight.scss';
</style>